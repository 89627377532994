import React, { Component } from 'react';
import '../Info/Info.css';

class InfoContent extends Component {
  render = () => {
    return (
      <div>
        <p>{this.props.text}</p>
        <h2 className="c-info__content__title">Related strategies</h2>
        <p>{this.props.relatedStrategies}</p>
        <h2 className="c-info__content__title">Example</h2>
        <p>{this.props.example}</p>
      </div>
    );
  }
}

export default InfoContent;
