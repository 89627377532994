import React, { Component } from 'react';
import './Favorites.css';
import Favorite from '../Favorite/Favorite';

class Favorites extends Component {
  handleFavoriteRemoveClick = event => this.props.onFavoriteRemoveClick(event)
  handleFavoriteInfoClick = event => this.props.onFavoriteInfoClick(event)

  render = () => {
    const favorites = this.props.favorites.map((favorite) =>
      <Favorite
        key={favorite}
        id={favorite}
        data={this.props.data}
        onFavoriteRemoveClick={this.handleFavoriteRemoveClick}
        onFavoriteInfoClick={this.handleFavoriteInfoClick} />
    );
    return (
      <ul className="c-favorites">{favorites}</ul>
    );
  }
}

export default Favorites;
