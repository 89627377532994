import React, { Component } from 'react';

import '../../assets/stylesheets/settings.global.css';
import '../../assets/stylesheets/settings.type.css';
import '../../assets/stylesheets/objects.container.css';
import '../../assets/stylesheets/objects.overlay.css';

import '../Content/Content.css';
import '../Footer/Footer.css';
import '../Header/Header.css';
import '../Main/Main.css';
import '../Menu/Menu.css';

import Categories from '../Categories/Categories';
import ContentMeta from '../ContentMeta/ContentMeta';
import Favorites from '../Favorites/Favorites';
import Info from '../Info/Info';
import InfoContent from '../InfoContent/InfoContent';
import Strategies from '../Strategies/Strategies';

class App extends Component {
  constructor(props) {
    super(props);
    this.data = JSON.parse(this.props.appData);

    this.state = {
      sortingMethod: 'relevance',
      info: {},
      infoVisible: false,
      favorites: [],
      weightedStrategies: this.data.bcts.map(strategy => {
        const {id} = strategy;
        return {id, weight: 0};
      }),
    }
  }

  handleCategoryChange = weightedStrategies => this.setState({weightedStrategies})
  handleSortingMethodChange = sortingMethod => this.setState({sortingMethod})

  handleCategoryInfoClick = event => {
    event.preventDefault();
    const id = event.currentTarget.dataset.id;
    const category = this.data.categories[id - 1];
    const type = this.data.categories[id - 1].type;
    const {title} = category;
    const text = <p>{category.text}</p>;
    this.setState({info: {id, type, title, text}});
    this.setState({infoVisible: true});
  }

  handleStrategyInfoClick = event => {
    event.preventDefault();
    const id = event.currentTarget.dataset.id;
    const strategy = this.data.bcts[id - 1];
    const {title} = strategy;
    const type = 'strategy';
    const text = <InfoContent
      text={strategy.text}
      relatedStrategies={strategy.relatedBcts}
      example={strategy.example} />
    this.setState({info: {id, type, title, text}});
    this.setState({infoVisible: true});
  }

  handleInfoCloseClick = event => {
    event.preventDefault();
    this.setState({info: {}});
    this.setState({infoVisible: false});
  }

  handleFavoriteClick = event => {
    event.preventDefault();
    const id = parseInt(event.currentTarget.dataset.id);
    let favorites = [];
    if (this.state.favorites.includes(id)) {
      favorites = this.state.favorites.filter(favorite => favorite !== id)
    } else {
      favorites = [...this.state.favorites, id];
    }
    this.setState({favorites: favorites});
  }

  render = () => {
    return (
      <div className="o-container">
      <header className="c-header">
        <h1 className="c-header__title">Pathfinder</h1>
        <p className="c-header__subtitle">Keuzetool voor de selectie van strategieën voor gedragsverandering</p>
      </header>
        <Info
          info={this.state.info}
          infoVisible={this.state.infoVisible}
          onHandleInfoCloseClick={this.handleInfoCloseClick} />
        <main className="c-main">
          <aside className="c-menu">
            <div className="c-menu__block">
              <header className="c-menu__header">
                <h2 className="c-menu__title">Lenzen / Aspecten</h2>
              </header>
              <Categories
                data={this.data}
                onCategoryChange={this.handleCategoryChange}
                onCategoryInfoClick={this.handleCategoryInfoClick} />
            </div>
            <div className="c-menu__block">
              <header className="c-menu__header">
                <h2 className="c-menu__title">Favorieten</h2>
              </header>
              <Favorites
                data={this.data.bcts}
                favorites={this.state.favorites}
                onFavoriteRemoveClick={this.handleFavoriteClick}
                onFavoriteInfoClick={this.handleStrategyInfoClick} />
            </div>
          </aside>
          <section className="c-content">
            <header className="c-content__header">
              <h2 className="c-content__title">Relevante strategieën</h2>
            </header>
            <ContentMeta
              weightedStrategies={this.state.weightedStrategies}
              onSortingMethodChange={this.handleSortingMethodChange} />
            <Strategies
              data={this.data.bcts}
              weightedStrategies={this.state.weightedStrategies}
              sortingMethod={this.state.sortingMethod}
              favorites={this.state.favorites}
              handleStrategyInfoClick={this.handleStrategyInfoClick}
              handleFavoriteClick={this.handleFavoriteClick} />
          </section>
        </main>
        <footer className="c-footer">
          <p>&copy; 2018 - Confidential Prototype - for testing purposes only</p>
        </footer>
      </div>
    );
  }
}

export default App;
