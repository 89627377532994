import React, { Component } from 'react';
import './Category.css';
import '../Icon/Icon.css';

class Category extends Component {
  handleCategoryInfoClick = event => this.props.onCategoryInfoClick(event)

  render = () => {
    const category = this.props.data;
    const className = `c-category c-category--${category.type}`;
    const name = `category${category.id}`;

    return (
      <p className={className}>
        <input
          onChange={this.props.handleChange}
          className="c-category__checkbox"
          id={name} type="checkbox"
          name="category"
          value={category.id} />
        <label
          className="c-category__label"
          htmlFor={name}>{category.title}
        </label>
        <a
          onClick={this.handleCategoryInfoClick}
          data-id={category.id}
          className="c-category__help c-icon c-icon-container"
          href="/">
          <svg
            className="c-icon c-icon--help"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 17 17">
            <title>Informatie</title>
            <path d="M9.37,4.19a.83.83,0,0,1-.9.83.81.81,0,0,1-.85-.83.88.88,0,0,1,1.75,0ZM7.8,13.8V6.29H9.21V13.8Z"/>
            <path d="M8.5,16.74A8.38,8.38,0,0,1,0,8.5,8.38,8.38,0,0,1,8.5.26,8.38,8.38,0,0,1,17,8.5,8.38,8.38,0,0,1,8.5,16.74Zm0-15.5A7.4,7.4,0,0,0,1,8.5,7.4,7.4,0,0,0,8.5,15.76,7.4,7.4,0,0,0,16,8.5,7.4,7.4,0,0,0,8.5,1.24Z"/>
          </svg>
        </a>
      </p>
    );
  }
}

export default Category;
