import React, { Component } from 'react';

class IconFavorite extends Component {
  render = () => {
    return(
      <svg
        className={this.props.className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 28.08 26.7">
        <title>Favoriet</title>
        <path className="c-icon__path" d="M22.72,26.7,14,20.4,5.36,26.7,8.68,16.5,0,10.2H10.72L14,0l3.31,10.2H28.08L19.4,16.5ZM14,19.16l6.77,4.93-2.58-8L25,11.2H16.63L14,3.24l-2.59,8H3.08l6.77,4.92-2.59,8Z"/>
      </svg>
    );
  }
}

export default IconFavorite;
