import React, { Component } from 'react';
import './Info.css';
import IconClose from '../Icon/IconClose';

class Info extends Component {
  handleInfoCloseClick = event => this.props.onHandleInfoCloseClick(event)

  render = () => {
    return (
      <div className={this.props.infoVisible
        ? 'c-info c-info--is-visible'
        : 'c-info'}>
        <div className="o-container">
          <div className="c-info__inner">
            <header className={'c-info__header c-info__header--' + this.props.info.type}>
              <h1 className="c-info__heading">{this.props.info.title}</h1>
              <a
                onClick={this.handleInfoCloseClick}
                href="/"
                className="c-info__close">
                <IconClose
                  className="c-icon c-icon--close"
                  title="Verwijder"
                />
              </a>
            </header>
            <div className="c-info__content">{this.props.info.text}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default Info;
