import React, { Component } from 'react';
import Category from '../Category/Category';

class Categories extends Component {
  constructor(props) {
    super(props);
    this.categories = this.props.data.categories;
    this.selectedCategories = [];
    this.strategies = this.props.data.bcts;
  }

  handleCategoryInfoClick = event => this.props.onCategoryInfoClick(event)

  handleCategoryChange = event => {
    if (event.target.checked) { this.addCategory(parseInt(event.target.value)) }
    if (! event.target.checked) { this.removeCategory(parseInt(event.target.value)) }
  }

  addCategory = category => {
    this.selectedCategories = [...this.selectedCategories, category];
    this.setWeights();
  }

  removeCategory = deletedCategory => {
    this.selectedCategories = this.selectedCategories.filter(selectedCategory => {
      if (selectedCategory === deletedCategory) { return false }
      if (this.categories[selectedCategory - 1].parent === deletedCategory) { return false }
      return true;
    });
    this.setWeights();
  }

  setWeights = () => {
    const weightedStrategies = this.strategies.map(strategy => {
      const { id } = strategy;
      return { id, weight: 0 };
    })

    // Get all parent categories that have subcategories selected
    let selectedParentCategories =
      this.selectedCategories
        .filter(category => this.categories[category - 1].type === 'aspect')
        .map(category => this.categories[category - 1].parent)

    let uniqueSelectedParentCategories = [...new Set(selectedParentCategories)];

    // Remove those parent categories because we don't count their weights
    const selectedCategories = this.selectedCategories.filter(category =>
      !uniqueSelectedParentCategories.includes(category));

    selectedCategories.forEach(category => {
      weightedStrategies.map((strategy, index) => {
        strategy.weight += this.categories[category - 1].weights[index].weight;
        return strategy;
      });
    });

    this.props.onCategoryChange(weightedStrategies);
  }

  isActiveCategory = category => {
    return typeof category.parent === 'undefined'
      || this.selectedCategories.includes(category.parent);
  }

  render = () => {
    return (
      <form className="c-categories">
        {
          this.categories.map(category => {
            if (this.isActiveCategory(category)) {
              return <Category
                data={category}
                key={category.id}
                handleChange={this.handleCategoryChange}
                onCategoryInfoClick={this.handleCategoryInfoClick} />
            } else {
              return '';
            }
          })
        }
      </form>
    );
  }
}

export default Categories;
