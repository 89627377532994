import React, { Component } from 'react';
import './ContentMeta.css';

class ContentMeta extends Component {
  handleChange = event => this.props.onSortingMethodChange(event.target.value)

  render = () => {
    const filteredTotal = this.props.weightedStrategies.filter(strategy => strategy.weight > 0).length
    const total = (filteredTotal > 0 ? filteredTotal : this.props.weightedStrategies.length)
    return (
      <div className="c-content-meta">
        <div className="c-content-meta__amount">Resultaten ({total})</div>
        <select
          value={this.props.sortingMethod}
          onChange={this.handleChange}
          className="c-content-meta__sort">
          <option value="relevance">Relevantie</option>
          <option value="favorite">Favoriet</option>
          <option value="alphabet">Alfabet</option>
        </select>
      </div>
    );
  }
}

export default ContentMeta;
