import React, { Component } from 'react';
import './Favorite.css';
import IconClose from '../Icon/IconClose';

class Favorite extends Component {
  handleFavoriteRemoveClick = event => this.props.onFavoriteRemoveClick(event)
  handleFavoriteInfoClick = event => this.props.onFavoriteInfoClick(event)

  render = () => {
    return (
      <li className="c-favorite">
        <a
          className="c-favorite__title"
          onClick={this.handleFavoriteInfoClick}
          data-id={this.props.id}
          href="/">
          {this.props.data[this.props.id - 1].title}
        </a>
        <a
          onClick={this.handleFavoriteRemoveClick}
          className="c-favorite__close c-icon-container"
          data-id={this.props.id}
          href="/">
          <IconClose
            className="c-icon c-icon--close c-icon--close--small"
            title="Verwijder" />
        </a>
      </li>
    );
  }
}

export default Favorite;
