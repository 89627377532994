import React, { Component } from 'react';
import './Strategies.css';
import Strategy from '../Strategy/Strategy';

class Strategies extends Component {
  constructor(props) {
    super(props);
    this.strategies = this.props.data;
  }

  handleStrategyInfoClick = event => this.props.handleStrategyInfoClick(event)
  handleFavoriteClick = event => this.props.handleFavoriteClick(event)
  filterByWeight = (strategy, index) => this.props.weightedStrategies[index].weight > 0
  isFavorite = id => this.props.favorites.includes(id)

  sortByRelevance = (x, y) => {
    const strategies = this.props.weightedStrategies;
    if (strategies[x.id - 1].weight > strategies[y.id - 1].weight) { return -1; }
    if (strategies[x.id - 1].weight < strategies[y.id - 1].weight) { return 1; }
    return 0;
  }

  sortByFavorite = (x, y) => {
    const xIsFavorite = this.isFavorite(x.id);
    const yIsFavorite = this.isFavorite(y.id);
    if (xIsFavorite && !yIsFavorite) { return -1; }
    if (!xIsFavorite && yIsFavorite) { return 1; }
    return 0;
  }

  sortByAlphabet = (x, y) => {
    const xTitle = this.strategies[x.id - 1].title.toLowerCase();
    const yTitle = this.strategies[y.id - 1].title.toLowerCase();
    if (xTitle < yTitle) { return -1; }
    if (xTitle > yTitle) { return 1; }
    return 0;
  }

  render = () => {
    let strategies = this.strategies.filter(this.filterByWeight);
    if (this.props.sortingMethod === 'alphabet') {
      strategies.sort(this.sortByAlphabet);
    }
    if (this.props.sortingMethod === 'relevance') {
      strategies.sort(this.sortByRelevance);
    }
    if (this.props.sortingMethod === 'favorite') {
      strategies.sort(this.sortByFavorite);
    }
    if (strategies.length <= 0) {
      strategies = this.strategies;
      strategies.sort(this.sortByAlphabet);
    }
    return (
      <div className="c-strategies">
        {
          strategies
            .map((strategy) =>
              <Strategy
                data={strategy}
                key={strategy.id - 1}
                favorite={this.isFavorite(strategy.id)}
                weight={this.props.weightedStrategies[strategy.id - 1].weight}
                handleStrategyInfoClick={this.handleStrategyInfoClick}
                handleFavoriteClick={this.handleFavoriteClick} />
            )
        }
      </div>
    );
  }
}

export default Strategies;
