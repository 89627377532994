import React, { Component } from 'react';
import './Strategy.css';
import { getFirstTenWords } from '../../helpers';
import IconFavorite from '../Icon/IconFavorite';

class Strategy extends Component {
  handleStrategyInfoClick = event => this.props.handleStrategyInfoClick(event)
  handleFavoriteClick = event => this.props.handleFavoriteClick(event)

  render = () => {
    const weight = this.props.weight.toFixed(4);
    const favorite = (this.props.favorite
      ? 'c-strategy__favorite c-strategy__favorite--is-favorite'
      : 'c-strategy__favorite');
    return (
      <div className="c-strategy">
        <a
          onClick={this.handleStrategyInfoClick}
          data-id={this.props.data.id}
          href="/"
          className="c-strategy__link">
          <h3 className="c-strategy__title" >{this.props.data.title}</h3>
        </a>
        <div className="c-strategy__body">
          <p
            className="c-strategy__content">{getFirstTenWords(this.props.data.text)}</p>
          <div className="c-strategy__meta">
            <div className="c-strategy__weight">Relevantie: {weight}</div>
            <a
              onClick={this.handleFavoriteClick}
              data-id={this.props.data.id}
              className={favorite}
              href="/">
              <IconFavorite className="c-icon c-icon--favorite" />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default Strategy;
