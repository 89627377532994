import React, { Component } from 'react';

class IconClose extends Component {
  render = () => {
    return(
      <svg
        className={this.props.className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 13.41 13.41">
        <title>{this.props.title}</title>
        <g>
          <polygon
            points="13.41 1.41 12 0 6.71 5.29 1.41 0 0 1.41 5.29 6.71
            0 12 1.41 13.41 6.71 8.12 12 13.41 13.41 12 8.12 6.71
            13.41 1.41"/>
        </g>
      </svg>
    );
  }
}

export default IconClose;
