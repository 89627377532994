import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App/App.js';
import * as serviceWorker from './serviceWorker';

let xhr = new XMLHttpRequest();
let data = {};
xhr.onreadystatechange = () => {
  if (xhr.readyState === 4 && xhr.status === 200) {
    data = JSON.parse(xhr.responseText);
    ReactDOM.render(<App appData={JSON.stringify(data)}/>, document.getElementById('root'));
  }
}

xhr.open("GET", `${process.env.PUBLIC_URL}/BCT-list.json`, true);
xhr.send();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
